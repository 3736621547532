import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorinterceptor implements HttpInterceptor {
    constructor(private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (request.url.includes('https://iid.googleapis.com')) {
        return next.handle(request);
      }

        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {

                localStorage.removeItem('valley_of_rockville_centre_token');
                localStorage.removeItem('valley_of_rockville_centre_FirstName');
                localStorage.removeItem('valley_of_rockville_centre_LastName');
                localStorage.removeItem('valley_of_rockville_centre_LodgeId');
                localStorage.removeItem('valley_of_rockville_centre_SubLodgeId');
                localStorage.removeItem('valley_of_rockville_centre_UserId');
                localStorage.removeItem('valley_of_rockville_centre_Email');
                localStorage.removeItem('valley_of_rockville_centre_UserImage');
                localStorage.removeItem('valley_of_rockville_centre_Role_id');
                localStorage.removeItem('valley_of_rockville_centre_EmailVerifyed');
                localStorage.removeItem('valley_of_rockville_centre_Degrees');
                // auto logout if 401 response returned from api
                this.router.navigate(['/account/login']);
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}
