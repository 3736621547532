export const environment = {
  // Development
  // production: false,
  // customerPortalUrl: "https://billing.stripe.com/p/login/test_bIYbMq54Z4hoggw000",
  // webAppUrl: "http://localhost:4200",
  // apiUrl: "http://localhost:8080",
  // stripeKey: "pk_test_LOe2Bdz3jrwziXzFXNx5aQNH",
  // stripePrice160: "price_1Pxs1JESJx0zyFha5IaMIqvS",
  // stripePrice320: "price_1Pxs6yESJx0zyFhao8f5l1sg",
  // Production
  production: true,
  customerPortalUrl: "https://billing.stripe.com/p/login/eVa2an203cRWcLe9AA",
  webAppUrl: "https://valleyrvc.org",
  apiUrl: "https://subscriptions-361871000284.us-east4.run.app",
  stripeKey: "pk_live_xPvOiBqve9x3RCuUUQy4PWlW",
  stripePrice160: "price_1Q3lIXA4GWCvnKXqWxOE9uzv",
  stripePrice320: "price_1Q3lKiA4GWCvnKXqNlkmkRmP",
  olympusKey: "ee8bb28019e56849fa6bd60b0815bb24adc80473",
  ServerUrl: window["envFrnt"]["ServerUrl"] || "default",
  MerchantAmount: window["envFrnt"]["MerchantAmount"] || "default",
  stripePlanKey: window["envFrnt"]["stripePlanKey"] || "default",
  stripePublishableKey: window["envFrnt"]["stripePublishableKey"] || "default",
  stripeSecretKey: window["envFrnt"]["stripeSecretKey"] || "default",
  GoogleStoragePath: window["envFrnt"]["GoogleStoragePath"] || "default",
  InstaToken: window["envFrnt"]["InstaToken"] || "default",
  PortalName: window["envFrnt"]["portalName"] || "default",
  faveico: window["envFrnt"]["faveico"] || "default",
  Site : "valley-of-rockville-centre",
  firebase: {
    apiKey: "AIzaSyDtUFk2CLJ4fG_6IBsQIfgch2fOatQVK7o",
    authDomain: "valleyrvc.org",
    projectId: "olympus-317810",
    storageBucket: "olympus-317810.appspot.com",
    messagingSenderId: "361871000284",
    appId: "1:361871000284:web:a9b0b01eb07cb17a82ea19",
    measurementId: "G-SFNP1Y1FXX"
  }
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
