import { Component, OnInit,OnDestroy  } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import {environment} from '../../environments/environment';
import { UserSettingService } from '../service/user-setting.service';
import { ProfileService } from '../service/profile.service';
import { EventService } from '../service/event.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as firebase from 'firebase/app';
import * as $ from 'jquery';
import {Observable, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, filter, switchMap, catchError} from 'rxjs/operators';
import { SearchComponent } from '../pages/search/search.component';

@Component({
  selector: 'app-header-withlogin',
  templateUrl: './header-withlogin.component.html',
  styleUrls: ['./header-withlogin.component.css']
})
export class HeaderWithloginComponent implements OnInit,OnDestroy  {

  
  closeResult: string;
  modalOptions: NgbModalOptions;

  subscription: Subscription;
  logo = '';
  baseUrl: string = environment.ServerUrl;
  roleId = window.localStorage.getItem('valley_of_rockville_centre_Role_id');
  userId = window.localStorage.getItem('valley_of_rockville_centre_UserId');
  emailId = window.localStorage.getItem('valley_of_rockville_centre_Email');
  image = window.localStorage.getItem('valley_of_rockville_centre_UserImage');
  profileNameChange = window.localStorage.getItem('valley_of_rockville_centre_ProfileNameChange');
  googleStoragePath: string = environment.GoogleStoragePath;
  name = window.localStorage.getItem('valley_of_rockville_centre_FirstName');
  last_name = window.localStorage.getItem('valley_of_rockville_centre_LastName');
  firstchar = '';

  currentpath: string;
  hideheader = false;
  searchtext = null;
  searchDropdown = 'Event';
  GetLinkDetail = [];
  chkimg = false;
  imgurl = '';
  constructor(
    private profileService: ProfileService,
    private eventService: EventService,
    private modalService: NgbModal,
    private location: Location,
    private router: Router,
    public afAuth: AngularFireAuth,
    private userSettingService: UserSettingService) {
    router.events.subscribe((val) => {
        this.currentpath = location.path();
        this.HeaderSetting(this.currentpath);
    });
    this.modalOptions = {
      size: 'xl',
      windowClass : 'search_popup'
    };
  }

  searchOpen(){
    const modalRef = this.modalService.open(SearchComponent,this.modalOptions);
    modalRef.result.then((result) => {
     
    }).catch((err)=> {
      
    });
  }

  HeaderSetting(url){
    this.hideheader = url === '/account/login' || url === '/account/signup' || url === '/account/forgotpassword';
  }

  hidemenu(){
    if ($('body').hasClass('openNav')) {
      $('body').removeClass('openNav');
    }
  }

  selectedItem(obj, input){
    obj.preventDefault();
    // tslint:disable-next-line:variable-name
    let _type = '';
    let text = '';
    input.value = '';
    if (obj.item != null){
      $('.searchIcon').click();
      if (obj.item.type === 'user'){
        _type = 'Brother';
        text = obj.item.name;
        this.searchtext = null;
        this.ProfileView(obj.item.value);
      }
      else if (obj.item.type === 'event'){
        _type = 'Event';
        text = obj.item.name;

        this.EventView(obj.item.value);
      }
    }
  }

  ProfileView(UserId){
    localStorage.setItem('valley_of_rockville_centre_ProfileSeach', '1');
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/profiles/detail/' + UserId]);
    });
  }

  EventView(EventId){
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/events/detail/' + EventId]);
    });
  }

  isObject(val) {
    return (typeof val === 'object');
  }




profilelabel() {
  if (this.profileNameChange != null) {
      return this.profileNameChange;
  }
}

SearchClick(e){
  if (e.keyCode === 13){

  }
}


UserImage() {
  if (this.image != null) {
      if (this.image === 'undefined' || this.image === 'null') {
        return this.baseUrl + 'Upload/UserPhoto/addofficer_profile.png';

      }
      else {
        //return this.baseUrl + 'Upload/UserPhoto/thumbs/' + this.image;
        return this.image;

      }

  }
  else {
    return this.baseUrl + 'Upload/UserPhoto/addofficer_profile.png';
  }

}

detailProfile(){
 this.router.navigate(['/profiles/detail/' + this.userId]);
}

  UserLogout(){
    localStorage.removeItem('valley_of_rockville_centre_token');
    localStorage.removeItem('valley_of_rockville_centre_FirstName');
    localStorage.removeItem('valley_of_rockville_centre_LastName');
    localStorage.removeItem('valley_of_rockville_centre_LodgeId');
    localStorage.removeItem('valley_of_rockville_centre_SubLodgeId');
    localStorage.removeItem('valley_of_rockville_centre_UserId');
    localStorage.removeItem('valley_of_rockville_centre_Email');
    localStorage.removeItem('valley_of_rockville_centre_UserImage');
    localStorage.removeItem('valley_of_rockville_centre_Role_id');
    localStorage.removeItem('valley_of_rockville_centre_EmailVerifyed');
    localStorage.removeItem('valley_of_rockville_centre_Degrees');
    localStorage.removeItem('valley_of_rockville_centre_Home');
    localStorage.removeItem('valley_of_rockville_centre_firebase_serverkey');
    // this.router.navigate(['/account/login']);
    this.afAuth.signOut();
    window.location.href = window.location.origin + '/account/login';
  }

  // linkDetail() {
  //   const that = this;
  //   that.userSettingService.GetLink().subscribe(
  //     datares => {
  //       if (datares != null) {
  //         that.GetLinkDetail = datares;
  //       }
  //     },
  //     (err) => {console.log(err)});
  // }

  resultFormat(value: any) {
    if (value.type === 'user'){
      return 'Brother : '  + value.name;
    }
    else if (value.type === 'business'){
      return 'Business : '  + value.name;
    }
    else if (value.type === 'event'){
      return 'Event : '  + value.name;
    }
    else{
      return value.name;
    }

  }
  inputFormat(value: any)   {
    if (value.name) {
      return value.name;
    }
    return value;
  }

  changeOption(e){
    this.searchDropdown = e;
  }

  search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((searchText) =>  this.profileService.HomeSearch(searchText))
    );
  }

  getDetail(){
    this.userSettingService.GetDetail().subscribe(
      datares => {
        //this.logo = this.googleStoragePath + 'Upload/Logo/' + datares.LogoName;
        this.logo = datares.LogoName;
    });
  }



  ngOnInit(): void {
    this.getDetail();
    if (this.image != null) {
      if (this.image === 'undefined' || this.image == "null") {
        this.chkimg = false;
      }
      else {
        //this.imgurl = this.googleStoragePath + 'Upload/UserPhoto/thumbs/' + this.image;
        this.imgurl = this.image;
        this.chkimg = true;
      }

  }
  else {
    this.chkimg = false;
  }
    if (this.name != null){
      this.firstchar = this.name.slice(0, 1);
    }
    //this.linkDetail();
   
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
}

}
