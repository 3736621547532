import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable, of} from "rxjs/index";
import {catchError, tap} from "rxjs/operators";
import {SubscriptionResponse} from "../../interfaces/subscription";
import {SubscriptionSearch} from "../../interfaces/subscription-search";

@Injectable({
  providedIn: 'root'
})
export class DuesPaymentPlanService {
  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': environment.olympusKey }),
  };


  apiUrl = environment.apiUrl;

  searchSubscription(search: SubscriptionSearch): Observable<SubscriptionResponse> {
    const url = `${this.apiUrl}/v1/subscriptions/search`;

    return this.http.post<SubscriptionResponse>(url, search, this.httpOptions).pipe(
      tap((resp: SubscriptionResponse) => {}),
      catchError(this.handleError<SubscriptionResponse>(`searchSubscription(email=${search.email})`)),
    );
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error}`);
      return of(result as T);
    };
  }

}
