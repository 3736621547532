
export const environment = {
  production: false,
  ServerUrl: window["envFrnt"]["ServerUrl"] || "default",
  MerchantAmount: window["envFrnt"]["MerchantAmount"] || "default",
  stripePlanKey: window["envFrnt"]["stripePlanKey"] || "default",
  stripePublishableKey: window["envFrnt"]["stripePublishableKey"] || "default",
  stripeSecretKey: window["envFrnt"]["stripeSecretKey"] || "default",
  GoogleStoragePath: window["envFrnt"]["GoogleStoragePath"] || "default",
  InstaToken: window["envFrnt"]["InstaToken"] || "default",
  PortalName: window["envFrnt"]["portalName"] || "default",
  faveico: window["envFrnt"]["faveico"] || "default",
  Site : "valley-of-rockville-centre",
  firebase: {
    apiKey: "AIzaSyDtUFk2CLJ4fG_6IBsQIfgch2fOatQVK7o",
    authDomain: "olympus-317810.firebaseapp.com",
    projectId: "olympus-317810",
    storageBucket: "olympus-317810.appspot.com",
    messagingSenderId: "361871000284",
    appId: "1:361871000284:web:a9b0b01eb07cb17a82ea19",
    measurementId: "G-SFNP1Y1FXX"
  }
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
