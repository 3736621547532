import { Component, OnInit } from '@angular/core';
import {DuesPlan} from "../../interfaces/dues-plan";
import * as $ from "jquery";
import {DuesPaymentPlanService} from "../../services/subscriptions/dues-payment-plan.service";
import {SubscriptionResponse} from "../../interfaces/subscription";
import {UtilsService} from "../../utils/utils.service";
import {SubscriptionSearch} from "../../interfaces/subscription-search";
import {environment} from "../../../environments/environment";


@Component({
  selector: 'app-dues-payment-plan',
  templateUrl: './dues-payment-plan.component.html',
  styleUrls: ['./dues-payment-plan.component.css']
})
export class DuesPaymentPlanComponent implements OnInit {

  constructor(
    private subscriptionService: DuesPaymentPlanService,
    private utilsService: UtilsService,
    ) { }

  active: boolean;
  paymentFailure: boolean;
  latestPaymentFailureDate: string;

  data: SubscriptionResponse;
  search: SubscriptionSearch;

  duesAmount: number = 160;
  currentYear: number = new Date().getFullYear();

  userId = window.localStorage.getItem('valley_of_rockville_centre_UserId');
  emailAddress = window.localStorage.getItem('valley_of_rockville_centre_Email');
  siteId = "valley-of-rockville-centre";

  oneYear: boolean = false;
  twoYears: boolean = false;

  subscriptionManagementUrl: string = environment.customerPortalUrl;

  plan1: DuesPlan = {id: 1, name: "Dues Payment Plan $160.00", total: 160, priceId: environment.stripePrice160, duration: 4};
  plan2: DuesPlan = {id: 2, name: "Dues Payment Plan $320.00", total: 320, priceId: environment.stripePrice320, duration: 8};

  plans: DuesPlan[] = [];

  selectPlan(plan: DuesPlan): void {
    this.oneYear = plan.id === 1;
    this.twoYears = plan.id === 2;
    if (plan.id === 1) {
      $('#' + plan.id).addClass("donation_button_hover");
      $('#' + 2).removeClass("donation_button_hover");
    } else {
      $('#' + plan.id).addClass("donation_button_hover");
      $('#' + 1).removeClass("donation_button_hover");
    }
  }


  openSubscriptionManagementUrl(): void {
    window.open(this.subscriptionManagementUrl, '_blank');
  }


  ngOnInit(): void {
    this.plans.push(this.plan1);
    this.plans.push(this.plan2);
    this.search = {email: this.emailAddress} as SubscriptionSearch;

    console.log(JSON.stringify(this.search));

    this.subscriptionService.searchSubscription(this.search).subscribe(
      data => {
        if (data.subscription) {
          this.data = data;
          this.active = data.subscription.active;
          if (data.subscription.payment_failures.length > 0){
            this.paymentFailure = true;
            this.latestPaymentFailureDate = this.utilsService.formatDate(
              data.subscription.payment_failures[data.subscription.payment_failures.length - 1]
            );
          }
        } else {
          this.active = false;
        }
      });

  }

}
