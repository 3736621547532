<div class="" style="text-align: center;">
    <div style="border-radius:80px; height:80px; width:80px; background: #F8FAF5; margin:0 auto;">
      <i class="checkmark">&#10004;</i>
    </div>
      <h1>Thank You</h1>
      <p>Transaction completed successfully.</p><br/><br/>
      <button class="yellowBtn" style="padding: 8px;" [routerLink]="['/']">
        continue
    </button>
</div>
<br/><br/><br/><br/><br/><br/>
