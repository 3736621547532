<div class="timeline">
  <ng-container *ngFor="let month of months; let i = index">
    <div class="timeline-item">
      <div class="timeline-content"><span>$</span>{{month.amount}}<span>.50</span></div>
      <div class="timeline-date">{{month.text}}</div>
    </div>
    <div *ngIf="i < months.length - 1" class="timeline-line"></div>
  </ng-container>
</div>

