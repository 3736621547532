<!-- <div id="container">
    <div id="success-box">
        <div class="dot"></div>
        <div class="dot two"></div>
        <div class="face">
            <div class="eye"></div>
            <div class="eye right"></div>
            <div class="mouth happy"></div>
        </div>
        <div class="shadow scale"></div>
        <div class="message">
            <h1 class="alert">Success!</h1>
            <p>You have checked in Successfully.</p>
        </div>
        <button class="button-box" [routerLink]="['/']">
            <h1 class="green">continue</h1>
        </button>
    </div>
</div> -->
<div class="" style="text-align: center;">
    <div style="border-radius:80px; height:80px; width:80px; background: #F8FAF5; margin:0 auto;">
      <i class="checkmark">&#10004;</i>
    </div>
      <h1>Thank You</h1> 
      <p>Your payment was processed successfully.</p><br/><br/>
      <button class="yellowBtn" style="padding: 8px;" [routerLink]="['/']">
        continue
    </button>
</div>
<br/><br/><br/><br/><br/><br/>