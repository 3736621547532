import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  formatDate(timestamp: number): string {
    const milliseconds = timestamp * 1000;
    let date = new Date(milliseconds);
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZoneName: "short"
    }).format(date);
  }

  getFutureDate(rawDate: Date, noMonths: number): string {
    let date = new Date(rawDate.getFullYear(), rawDate.getMonth() + noMonths, rawDate.getDate());
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    }).format(date);
  }

  formatCurrency(amount: number, skipCurrency: boolean = false): string {
    let newAmount: string = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount);

    if (skipCurrency) {
      return newAmount.substring(1, 3);
    } else {
      return newAmount;
    }

  }

  constructor() { }
}
