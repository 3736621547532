<header class="header" *ngIf="!hideheader">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-auto">
				<div class="logo">
					<a [routerLink]="['../home']">
						<img [src]="logo" alt="">
					</a>
				</div>
			</div>
			<div class="col-auto col-md navCol text-center">
				<div class="mainNav">
					<button class="mobileNavBtn">
						<span></span>
						<span></span>
						<span></span>
					</button>
					<div class="overLay"></div>
					<nav class="navigation">
						<ul>
							<li><a (click)="hidemenu()" [routerLink]="['../home']">HOME</a></li>
							<li><a (click)="hidemenu()" [routerLink]="['../officers']">OFFICERS</a></li>
							<li><a (click)="hidemenu()" [routerLink]="['../events/upcoming']">EVENTS</a></li>
							<li class="hasChild">
								<a href="javascript:void(0)">RESOURCES</a>
								<div class="subMenu resourcesSubMenu">
									<ul>
										<li>
											<div class="row">
												<div class="col-md-4">
													<a (click)="hidemenu()" [routerLink]="['../resources/paydues']"><i class="icon-svg icon-money"></i>&nbsp;Pay Dues</a>
													<a (click)="hidemenu()" [routerLink]="['../dues-payment-plan']"><i class="icon-svg icon-money"></i>&nbsp;Dues Payment Plan</a>
													<a (click)="hidemenu()" [routerLink]="['../resources/lecture']"><i class="icon-svg icon-blog"></i>&nbsp;Valley Lectures</a>
													<a (click)="hidemenu()" [routerLink]="['../resources/journal']"><i class="icon-svg icon-journal"></i>&nbsp;Eagle’s Journal</a>
												</div>
												<div class="col-md-3">
													<a (click)="hidemenu()" [routerLink]="['../resources/degree']"><i class="icon-svg icon-degree"></i>&nbsp;Degrees</a>
													<a (click)="hidemenu()" [routerLink]="['../resources/kde']"><i class="icon-svg icon-magazine"></i>&nbsp;KDE</a>
												</div>
												<div class="col-md-5 border_left">
													<h5 class="ml-2">External Links</h5>
													<a (click)="hidemenu()" href="https://scottishritenmj.org/membership-application" target="_blank">Petition Now</a>
													<a (click)="hidemenu()" href="https://scottishritenmj.org/" target="_blank">NMJ Website</a>
													<a (click)="hidemenu()" href="https://scottishritenmj.org/degree-dates" target="_blank">Degrees By State</a>
													<a (click)="hidemenu()" href="https://scottishritenmj.org/abbott" target="_blank">Abbott Scholarship Application 2023</a>
														<a (click)="hidemenu()" href="{{n.Link}}" target="_blank" *ngFor="let n of GetLinkDetail">
															{{n.Name}}
														</a>
                          </div>
											  </div>
										</li>
                 </ul>
								</div>
							</li>

							<li><a (click)="hidemenu()" [routerLink]="['../gallery']">GALLERY</a></li>
							<li class="mr-0 ml-3"><Button (click)="hidemenu()" class="btn btn-outline-secondary theme_button_outline btn-lg mr-1 mb-2" [routerLink]="['../resources/store']">STORE</Button></li>
							<li class="ml-3"><Button (click)="hidemenu()" class="btn btn-secondary theme_button btn-lg mb-2" [routerLink]="['../resources/philanthropy']">DONATE</Button></li>
						</ul>


					</nav>
				</div>
			</div>
			<div class="col col-md-auto">
				<div class="topRightLink">
					<div class="searchEle">
						<!-- <span class="searchIcon"></span> -->
						<span ><a href="javascript:void(0)" (click)="searchOpen()"><img src="/assets/images/search_icon.png" style="width: 35px;"></a></span>
						<!-- <div class="searchBox">
							<input type="text" #input placeholder="Search here..." id="searchtop" (keyup)="SearchClick($event)" ng-keypress="checkIfEnterKeyWasPressed($event)" [(ngModel)]="searchtext" (selectItem)="selectedItem($event,input)"  name="searchtop" [ngbTypeahead]="search" [inputFormatter]="inputFormat" [resultFormatter]="resultFormat" #instance="ngbTypeahead">
						</div> -->
					</div>
					<div class="loginLink" *ngIf="chkimg"><span class="circle" style="background: none;"><img [src]="imgurl" class="rounded-circle" ></span></div>
					<div class="loginLink" *ngIf="!chkimg"><span class="circle">{{firstchar}}</span></div>
					<div class="linkDropDown">
						<!-- <span class="text-muted smaller_font">&nbsp;{{emailId}}</span> -->
						<ul>
							<li><span class="text-muted ml-12 email-text">&nbsp;{{name}} {{last_name}}</span></li>
							<li><span class="text-muted ml-12 email-text">&nbsp;{{emailId}}</span></li>
							<hr />
							<li><a href="javascript:void(0);" (click)="detailProfile()">MY PROFILE</a></li>
							<li><a [routerLink]="['../profiles/changepassword']">CHANGE PASSWORD</a></li>
							<li><a [routerLink]="['../profiles/account']">PAYMENT METHODS</a></li>
							<li><a [routerLink]="['../resources/receipt']">RECEIPTS</a></li>
							<li><a href="javascript:void(0);" (click)="UserLogout()">LOGOUT</a></li>
						</ul>
						<hr/>
						<a href="javascript:void(0)" [routerLink]="['../resources/paydues']" class="btn-circle text-white float-left">PAY DUES&nbsp;<span class="btnicon"><i class="fa fa-arrow-right" style="color: black;" aria-hidden="true"></i></span></a>
						<br/>
						<br/>
					</div>
				</div>
			</div>
		</div>
	</div>
</header>
