import {Component, Input, OnInit} from '@angular/core';
import {Checkout} from "../../interfaces/checkout";
import {environment} from "../../../environments/environment";
import {switchMap} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {StripeService} from "ngx-stripe";


@Component({
  selector: 'app-subscription-button',
  templateUrl: './subscription-button.component.html',
  styleUrls: ['./subscription-button.component.css']
})
export class SubscriptionButtonComponent implements OnInit {

  @Input() emailAddress: string;
  @Input() priceId: string;
  @Input() noMonths: number;
  @Input() userId: string;
  @Input() siteId: string;
  @Input() productName: string;
  @Input() checkoutText: string = "Checkout";

  constructor(
    private http: HttpClient,
    private stripeService: StripeService,
  ) { }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': environment.olympusKey }),
  };

  checkout(priceId) {
    this.http.post<Checkout>(`${environment.apiUrl}/v1/checkout-sessions`,
      {
        customer_email: this.emailAddress,
        price_id: priceId,
        server_url: environment.webAppUrl,
        metadata: {
        no_months: this.noMonths,
        user_id: this.userId,
        site_id: this.siteId,
        product_name: this.productName,
        },
      },
      this.httpOptions
    )
      .pipe(
        switchMap(session => {
          return this.stripeService.redirectToCheckout({ sessionId: session.id })
        }))
      .subscribe(result => {
        if (result.error) {
          alert(result.error.message);
        }
      });
  }

  ngOnInit(): void {

  }

}
