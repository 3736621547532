import { Component, OnInit,Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { PaymentService } from '../../service/payment.service';
import {UserSettingService} from '../../service/user-setting.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {ToasterMsgService} from '../../service/toaster.service';

@Component({
  selector: 'app-stripepayment',
  templateUrl: './stripepayment.component.html',
  styleUrls: ['./stripepayment.component.css']
})
export class StripepaymentComponent implements OnInit {
  @Input() price;
  @Input() merchantFee;
  @Input() disc;
  @Input() donationAmount;
  @Input() curyear;
  @Input() pastYear;
  @Input() futureYear;
  @Input() eid;
  @Input() numberOfGuests;
  @Input() type;

  logo = '';
  googleStoragePath: string = environment.GoogleStoragePath;
  stripePublishableKey: string = window["envFrnt"]["stripePublishableKey"];
  portalName: string = environment.PortalName;
  userId = window.localStorage.getItem('valley_of_rockville_centre_UserId');
  email = window.localStorage.getItem('valley_of_rockville_centre_Email');
  message = "";
  submitted = false;
  formProcess = false;

  currentYear: number = new Date(). getFullYear();
  years = []

  cardNum = "";

  public mask = {
    guide: false,
    showMask: false,
    mask: [/\d/, /\d/,/\d/, /\d/, ' ', /\d/, /\d/,/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]
  };

  customStripeForm = new FormGroup({
    cardNumber: new FormControl(''),
    expMonth: new FormControl(''),
    expYear: new FormControl(''),
    cvv: new FormControl('')
  }); 
  
  constructor(private ToasterService: ToasterMsgService,public activeModal: NgbActiveModal,private PaymentService: PaymentService,private userSettingService: UserSettingService,) {
    var lastyear = (this.currentYear + 10);
    for (var x = this.currentYear; x <= lastyear; x++) {
      this.years.push(x);
    }
  }

  onKeyCardValidation(value: any){
    
    var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    var matches = v.match(/\d{4,16}/g);
    var match = matches && matches[0] || ''
    var parts = []
    for (var i=0, len=match.length; i<len; i+=4) {
      parts.push(match.substring(i, i+4))
    }
    if (parts.length) {
      //this.cardNum = parts.join(' ');
      return parts.join(' ')
    } else {
      //this.cardNum = value;
      return value
    }
 }

   getDetail(){
    this.userSettingService.GetDetail().subscribe(
      datares => {
        this.logo = this.googleStoragePath + 'Upload/Logo/' + datares.LogoName;
    });
  }

  loadStripe() {
    if(!window.document.getElementById('stripe-custom-form-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-custom-form-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        window['Stripe'].setPublishableKey(this.stripePublishableKey);
      }
       
      window.document.body.appendChild(s);
    }
  }

  homedonation(form){
    var that = this;
    if(!window['Stripe']) {
      this.formProcess = false;
      this.ToasterService.showToast("error", "Payment", "Oops! Stripe did not initialize properly.");
      return;
    }
     
    this.submitted = true;
   
    if (this.customStripeForm.invalid) {  
      this.formProcess = false;    
      return;
    }   
   
    this.formProcess = true;
    if(!window['Stripe']) {
      this.formProcess = false;
      this.ToasterService.showToast("error", "Payment", "Oops! Stripe did not initialize properly.");
      return;
    }
    (<any>window).Stripe.card.createToken({
      number: form.cardNumber,
      exp_month: form.expMonth,
      exp_year: form.expYear,
      cvc: form.cvc
    }, (status: number, response: any) => {
      this.submitted = false;
      if (status === 200) {
        const body = new HttpParams()
        .set('stripeToken', response.id)
        .set('stripeEmail', that.email)
        .set('UserId', that.userId)
        .set('Price', that.price)
        .set('Disc', that.disc)
        
        that.PaymentService.ChargePhilanthropy(body).subscribe(
          res => {
            this.formProcess = false;
            if(res == 1){
              this.ToasterService.showToast("success", "Payment", "Your payment was processed successfully"); 
              this.activeModal.close('Success');
                       
            }
            else{
             this.ToasterService.showToast("error", "Payment", "Unsuccessfully your payment. please try again"); 
           }
          }
        );
        //this.message = `Success! Card token ${response.card.id}.`;
      } else {
        //this.message = response.error.message;
        this.ToasterService.showToast("error", "Payment", response.error.message);
      }
    });
  }

  store(form){
    var that = this;
    if(!window['Stripe']) {
      this.formProcess = false;
      this.ToasterService.showToast("error", "Payment", "Oops! Stripe did not initialize properly.");
      return;
    }
     
    this.submitted = true;
   
    if (this.customStripeForm.invalid) {      
      this.formProcess = false;
      return;
    }   
   
    if(!window['Stripe']) {
      this.formProcess = false;
      this.ToasterService.showToast("error", "Payment", "Oops! Stripe did not initialize properly.");
      return;
    }
    (<any>window).Stripe.card.createToken({
      number: form.cardNumber,
      exp_month: form.expMonth,
      exp_year: form.expYear,
      cvc: form.cvc
    }, (status: number, response: any) => {
      this.submitted = false;
      if (status === 200) {
        const body = new HttpParams()
        .set('stripeToken', response.id)
        .set('stripeEmail', that.email)
        .set('UserId', that.userId)
        .set('Price', this.price.toString())
        .set('MerchantFee', this.merchantFee.toString())
        .set('Disc', this.disc);

        that.PaymentService.ChargeCashRegister(body).subscribe(
          res => {
            this.formProcess = false;
            if(res == 1){
              this.ToasterService.showToast("success", "Payment", "Your payment was processed successfully"); 
              this.activeModal.close('Success');       
            }
            else{
              that.ToasterService.showToast("error", "Store", "Your payment was not successful. Please try again");          
            }
          }
        );
        
      
        //this.message = `Success! Card token ${response.card.id}.`;
      } else {
        this.formProcess = false;
        //this.message = response.error.message;
        this.ToasterService.showToast("error", "Payment", response.error.message);
      }
    });
  }

  
  addcard(form){
    var that = this;
    if(!window['Stripe']) {
      this.formProcess = false;
      this.ToasterService.showToast("error", "Payment", "Oops! Stripe did not initialize properly.");
      return;
    }
     
    this.submitted = true;
   
    if (this.customStripeForm.invalid) {  
      this.formProcess = false;    
      return;
    }   
   
     if(!window['Stripe']) {
      this.formProcess = false;
      this.ToasterService.showToast("error", "Payment", "Oops! Stripe did not initialize properly.");
      return;
    }
    


    (<any>window).Stripe.card.createToken({
      number: form.cardNumber,
      exp_month: form.expMonth,
      exp_year: form.expYear,
      cvc: form.cvc
    }, (status: number, response: any) => {
      this.submitted = false;
      if (status === 200) {
        const body = new HttpParams()
        .set('stripeToken', response.id)
        .set('stripeEmail', that.email)
        .set('UserId', that.userId)
        .set('CustomerId', that.eid);
        that.PaymentService.AddCardinfo(body).subscribe(
          res => {
            this.formProcess = false;
            if (res == 'Success'){
              this.ToasterService.showToast('success', 'Card info', 'Card Info successfully saved');
              this.activeModal.close('Success');   
            }
            else{
              this.ToasterService.showToast('error', 'Card info', 'Card Info not successfully saved');
            }
          }
        );

       
      
        //this.message = `Success! Card token ${response.card.id}.`;
      } else {
        this.formProcess = false;
        //this.message = response.error.message;
        this.ToasterService.showToast("error", "Payment", response.error.message);
      }
    });
  }

  pagedue(form){
    var that = this;
    if(!window['Stripe']) {
      this.formProcess = false;
      this.ToasterService.showToast("error", "Payment", "Oops! Stripe did not initialize properly.");
      return;
    }
     
    this.submitted = true;
   
    if (this.customStripeForm.invalid) {    
      this.formProcess = false;  
      return;
    }   
   
    if(!window['Stripe']) {
      this.ToasterService.showToast("error", "Payment", "Oops! Stripe did not initialize properly.");
      return;
    }
    (<any>window).Stripe.card.createToken({
      number: form.cardNumber,
      exp_month: form.expMonth,
      exp_year: form.expYear,
      cvc: form.cvc
    }, (status: number, response: any) => {
      this.submitted = false;
      if (status === 200) {
        const body = new HttpParams()
        .set('stripeToken', response.id)
        .set('stripeEmail', that.email)
        .set('UserId', that.userId)
        .set('Price', that.price)
        .set('Amount', "0")
        .set('MerchantFee', "0")
        .set('Disc', that.disc)
        .set('DonationAmount', that.donationAmount)
        .set('CurrentYear',that.curyear)
        .set('PastYear', that.pastYear)
        .set('FutureYear', that.futureYear)
        
        that.PaymentService.ChargePayDuePayment(body).subscribe(
          res => {
            this.formProcess = false;
            if(res == 1){
              this.ToasterService.showToast("success", "Payment", "Your payment was processed successfully");
              this.activeModal.close('Success');
                       
            }
            else{
              this.ToasterService.showToast("error", "Payment", "Unsuccessfully your payment. please try again");
           }
          }
        );
        //this.message = `Success! Card token ${response.card.id}.`;
      } else {
        this.formProcess = false;
        //this.message = response.error.message;
        this.ToasterService.showToast("error", "Payment", response.error.message);
      }
    });
  }

  payforDinner(form){
    var that = this;
    if(!window['Stripe']) {
      this.formProcess = false;
      this.ToasterService.showToast("error", "Payment", "Oops! Stripe did not initialize properly.");
      return;
    }
     
    this.submitted = true;
   
    if (this.customStripeForm.invalid) {     
      this.formProcess = false; 
      return;
    }   
   
    if(!window['Stripe']) {
      this.ToasterService.showToast("error", "Payment", "Oops! Stripe did not initialize properly.");
      return;
    }
    (<any>window).Stripe.card.createToken({
      number: form.cardNumber,
      exp_month: form.expMonth,
      exp_year: form.expYear,
      cvc: form.cvc
    }, (status: number, response: any) => {
      this.submitted = false;
      
      if (status === 200) {
        const body = new HttpParams()
        .set('stripeToken', response.id)
        .set('stripeEmail', that.email)
        .set('EventId', that.eid)
        .set('UserId', that.userId)
        .set('NumberofGuests', that.numberOfGuests)
        .set('Price', that.price)
        .set('Disc', 'Pay For Dinner');
        
        that.PaymentService.ChargePayForDinner(body).subscribe(
          res => {
            this.formProcess = false;
            if(res == 1){
              this.ToasterService.showToast("success", "Payment", "Your payment was processed successfully");
              this.activeModal.close('Success');
                       
            }
            else{
              this.ToasterService.showToast("error", "Payment", "Unsuccessfully your payment. please try again");
           }
          }
        );
        //this.message = `Success! Card token ${response.card.id}.`;
      } else {
        this.formProcess = false;
        //this.message = response.error.message;
        this.ToasterService.showToast("error", "Payment", response.error.message);
      }
    });
  }

  pay(form) {
    if(form.cardNumber == "" || form.cvv == "" || form.expMonth == "" || form.expYear == ""){
      this.ToasterService.showToast("error", "Payment", "Please enter card information.");
    }
    else{
      this.formProcess = true;
      if(this.type == "Store"){
          this.store(form);
      }
      else if(this.type == "AddCard"){
        this.addcard(form);
      }
      else if(this.type == "HomeDonation"){
        this.homedonation(form);
      }
      else if(this.type == "PayforDinner"){
        this.payforDinner(form);
      }
      else{
        this.pagedue(form);
      }
    }  

    
  }

  ngOnInit(): void {
  
    this.getDetail();
    this.loadStripe();
  }

}
