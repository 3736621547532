import { NgModule } from '@angular/core';
import { Routes, RouterModule,PreloadAllModules  } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';
import { TermsofuseComponent } from './pages/termsofuse/termsofuse.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { EmailresComponent } from './pages/emailres/emailres.component';
import { EmailverifyComponent } from './pages/emailverify/emailverify.component';
import { ScanComponent } from './pages/scan/scan.component';
import { SuccessComponent } from './pages/success/success.component';
import { FailureComponent } from './pages/failure/failure.component';
import {DuesPaymentPlanComponent} from "./components/dues-payment-plan/dues-payment-plan.component";

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module')
          .then(m => m.HomeModule),
      },
      {
        path: 'events',
        loadChildren: () => import('./pages/events/events.module')
          .then(m => m.EventsModule),
      },
       {
        path: 'resources',
        loadChildren: () => import('./pages/resources/resources.module')
          .then(m => m.ResourcesModule),
      },
      {
        path: 'officers',
        loadChildren: () => import('./pages/officers/officers.module')
          .then(m => m.OfficersModule),
      },
      {
        path: 'gallery',
        loadChildren: () => import('./pages/gallery/gallery.module')
          .then(m => m.GalleryModule),
      },
      {
        path: 'profiles',
        loadChildren: () => import('./pages/profiles/profiles.module')
          .then(m => m.ProfilesModule),
      },
      {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module')
          .then(m => m.AccountModule),
      },
      {
        path: 'success',
        component: SuccessComponent,
      },
      {
        path: 'failure',
        component: FailureComponent,
      },
      {
        path: 'scanner',
        component: ScanComponent,
      },
      {
        path: 'about',
        component: AboutComponent,
      },
      {
        path: 'privacypolicy',
        component: PrivacypolicyComponent,
      },
      {
        path: 'termsofuse',
        component: TermsofuseComponent,
      },
      {
        path: 'contactus',
        component: ContactusComponent,
      },
      {
        path: 'emailres',
        component: EmailresComponent,
      },
      {
        path: 'emailverify/:id',
        component: EmailverifyComponent,
      },
      {
        path: 'dues-payment-plan',
        component: DuesPaymentPlanComponent,
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      // {
      //   path: '**',
      //   component: MainComponent,
      // },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules,relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
